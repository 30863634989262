import React from 'react';
import { Menu, SplitPage, Title } from '../Elements';

const ContactWidget: React.FC = () => {
    return <>
        <Title title="contact" />
        <SplitPage
            leftPane={<>
                Puoi contattare l'autore per avere maggiori informazioni sulle sue mostre e sui suoi scatti a questo indirizzo<br />
                <br /><a href="mailto:innerart@virgilio.it" >innerart@virgilio.it</a><br /><br /><br />Se invece hai problemi a consultare
                il sito puoi contattare il webmaster a questo indirizzo<br /><br /><a href="mailto:admin@mariobianchi.eu">admin@mariobianchi.eu</a>
            </>}
            rightPane={<>
                You can contact the author to have more info about the exhibition and about his shots at this address<br /><br />
                <a href="mailto:innerart@virgilio.it" >innerart@virgilio.it</a><br /><br /><br />If you have problem visiting the site, you can contact
                our webmaster at this address<br /><br /><a href="mailto:admin@mariobianchi.eu">admin@mariobianchi.eu</a>
            </>}
        />
        <Menu />
    </>
}
export default ContactWidget
