import React from 'react';
import { Menu, Translation, Youtube } from '../Elements';
import { WithGallery } from '../../model';
import dx from '../../images/dx.gif'
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";

const GalleryWidget: React.FC<WithGallery> = (props) => {
    const [state, setState] = React.useState({
        galleryOpen: false
    })

    function openGallery() {
        setState({ galleryOpen: true })
    }

    function closeGallery() {
        setState({ galleryOpen: false })
    }
    

    return <div>
        <div id="images" >
            <Translation
                addClassName='title'
                leftElement={props.translations.titleIt}
                midElement='/'
                rightElement={props.translations.titleEn}
            />
            <Translation
                addClassName='year'
                leftElement={props.translations.yearStart}
                midElement='-'
                rightElement={props.translations.yearEnd}
            />
            <Translation
                addClassName='text'
                leftElement={props.translations.descriptionIt}
                midElement=' '
                rightElement={props.translations.descriptionEn}
            />
            <div className="clearfix gallery">
                <img src={dx} alt="" onClick={openGallery} />
            </div>
            <Lightbox
                plugins={[Captions, Youtube]}
                open={state.galleryOpen}
                close={closeGallery}
                controller={{closeOnBackdropClick: true}}
                slides={props.images.map((e, i) => {
                    if (!!e.video) return {
                        type: "youtube",
                        width: e.width || 1280,
                        height: e.height || 720,
                        title: e.captionIt,
                        description: e.captionEn,
                        youtube: e.video,
                    }
                    return {
                        type: "image",
                        src: e.image,
                        title: e.captionIt,
                        description: e.captionEn,
                        alt: 'Image ' + (i + 1),
                    }
                })}
            />
        </div>
        <Menu />
    </div>
}

export default GalleryWidget