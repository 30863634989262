import React from 'react';
import { Menu, SplitPage, Title } from '../Elements';

const CreditsWidget: React.FC = () => {
    return <>
        <Title title="credits" />
        <SplitPage
            leftPane={<>
                Sito creato da un'idea originale di <br /><b>Mario Bianchi</b> (fotografo) e sviluppata da <b>Bassani Matteo</b> (webmaster).<br /><br />
                Il fotografo ringrazia suo fratello Giorgio che ha creato il video di presentazione delle ricerche con dedizione, passione e grande sensibilità e Vivi Papi, suo premuroso maestro, e desidera ricordare una sua frase:<br /><br />
                <b><i>"Non mi sento superiore a nessuno, nemmeno al mondo naturale e animale.<br />Non penso che questi siano al <br />servizio dell'uomo e che sia giusto<br />considerarli inferiori."</i></b>
            </>}
            rightPane={<>
                Site created from <b>Mario Bianchi</b>'s <br />original idea (photograph) and devoloped by <b>Bassani Matteo</b> (webmaster).<br /><br />
                The  photografer thanks his brother Giorgio, for the researchs' videos, realized with passion, dedication and sensitivity, and Vivi Papi, his solicitous master, and whishes to remember what he said:<br /><br />
                <b><i>"I don't feel myself superior to anyone, neither to the natural and animal world.<br />I don't think them are subdued to the human race and isn't correct to think of them as inferior creatures."</i></b>
            </>}
        />
        <Menu />
    </>
}
export default CreditsWidget
