import React from 'react';
import { Menu, Title, YoutubeSlide } from '../Elements';

const VideoWidget: React.FC = () => {
    return <>
        <Title title="video" />
        <div style={{ marginTop: '61px' }} />
        <div style={{ margin: '0 auto' }}>
            <YoutubeSlide slide={{
                type: "youtube",
                width: 480,
                height: 360,
                title: 'Video 1',
                youtube: 'ZeQFEp5fhxA',
            }}
                offset={0} />
        </div>
        <Menu />
    </>
}
export default VideoWidget
