import React from 'react';
import { Menu, Title, YoutubeSlide } from '../Elements';

const ExhibitionsWidget: React.FC = () => {
    return <>
        <Title title="exhibitions" />
        <div style={{ marginTop: '61px' }} />
        <div style={{ margin: '0 auto' }}>

            <YoutubeSlide slide={{
                type: "youtube",
                width: 480,
                height: 360,
                title: 'Exhibition 1',
                youtube: 'ZyVBiLjnvZo',
            }}
                offset={0} />
        </div>
        <div style={{ margin: '17px auto 0 auto' }}>

            <YoutubeSlide slide={{
                type: "youtube",
                width: 480,
                height: 360,
                title: 'Exhibition 2',
                youtube: 'bl95DHsQWFc',
            }}
                offset={0} />
        </div>
        <Menu />
    </>
}
export default ExhibitionsWidget
