import React from 'react';
import { Link } from "react-router-dom";

const MenuWidget: React.FC = () => {
    return <React.Fragment>
        <div id="menu">
            <Link className="profile" to="/profile/" title="Profile">Profile</Link>
            <Link className="gallery" to="/gallery/" title="Gallery">Gallery</Link>
            <Link className="exhibition" to="/exhibitions/" title="Exhibitions">Exhibitions</Link>
            <Link className="video" to="/videos/" title="Videos">Videos</Link>
            <Link className="contact" to="/contact/" title="Contact">Contact</Link>
            <Link className="facebook" to="https://www.facebook.com/mario.bianchi.foto/" rel="noreferrer" target="_blank" title="Facebook">Facebook</Link>
            <Link className="review" to="/review/" target="_blank" title="Review">Review</Link>
            <Link className="disclaimer" to="/disclaimer/" title="Disclaimer">Disclaimer</Link>
            <Link className="credits" to="/credits/" title="Credits">Credits</Link>
        </div>
        <div id="push" />
    </React.Fragment>
}
export { }
export default MenuWidget