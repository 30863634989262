import * as React from "react";

import { PluginProps, Slide, SlideYoutube, useLightboxProps, LightboxProps } from "yet-another-react-lightbox";

declare module "yet-another-react-lightbox" {
    interface SlideTypes {
        /** youtube slide type */
        youtube: SlideYoutube;
    }
    /** Youtube slide attributes */
    interface SlideYoutube extends GenericSlide {
        /** youtube slide type */
        type: "youtube";
        /** youtube url */
        youtube: string;
        /** youtube placeholder image */
        poster?: string;
        /** youtube width in pixels */
        width: number;
        /** youtube height in pixels */
        height: number;
        /** if `true`, the youtube automatically begins to play */
        autoPlay?: boolean;
        /** if `true`, the browser will offer controls to allow the user to control youtube playback */
        controls?: boolean;
        /** indicates what controls to show */
        controlsList?: string;
        /** indicates whether to use CORS to fetch the related youtube */
        crossOrigin?: string;
        /** youtube preload setting */
        preload?: string;
        /** if `true`, the browser will automatically seek back to the start upon reaching the end of the youtube */
        loop?: boolean;
        /** the default setting of the audio contained in the youtube */
        muted?: boolean;
        /** if `true`, the youtube is to be played "inline", that is within the element's playback area */
        playsInline?: boolean;
        /** prevents the browser from suggesting a Picture-in-Picture context menu */
        disablePictureInPicture?: boolean;
        /** disables the capability of remote playback */
        disableRemotePlayback?: boolean;
    }
    interface LightboxProps {
        /** youtube plugin settings */
        youtube?: Pick<SlideYoutube, "autoPlay" | "controls" | "controlsList" | "crossOrigin" | "preload" | "loop" | "muted" | "playsInline" | "disablePictureInPicture" | "disableRemotePlayback">;
    }
}

function isYoutubeSlide(slide: Slide): slide is SlideYoutube {
    return slide.type === "youtube";
}

export const defaultYoutubeProps = {
    controls: true,
    playsInline: true,
};

export const resolveYoutubeProps = (youtube: LightboxProps["youtube"]) => ({
    ...defaultYoutubeProps,
    ...youtube,
});

export function useYoutubeProps() {
    const { youtube } = useLightboxProps();
    return resolveYoutubeProps(youtube);
}


export type YoutubeSlideProps = {
    slide: SlideYoutube;
    offset: number;
};

/** Youtube slide */
export function YoutubeSlide({ slide, offset }: YoutubeSlideProps) {
    return <>
        <iframe
            width={`${slide.width}`}
            height={`${slide.height}`}
            src={`https://www.youtube-nocookie.com/embed/${slide.youtube}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={`${slide.title}`}
        />
    </>
}

/** Youtube plugin */
export function Youtube({ augment }: PluginProps) {
    augment(({ render: { slide: renderSlide, ...restRender }, youtube, ...restProps }) => ({
        render: {
            slide: ({ slide, offset, rect }) => {
                if (isYoutubeSlide(slide)) {
                    return (
                        <YoutubeSlide
                            slide={slide}
                            offset={offset}
                        />
                    );
                }
                return renderSlide?.({ slide, offset, rect });
            },
            ...restRender,
        },
        youtube: resolveYoutubeProps(youtube),
        ...restProps,
    }));
}