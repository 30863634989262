import React from 'react';
import blank from '../../images/blank.png'

export type WithTitle = {
    title: string,
    section?: string
}

type Section = {
    titleMarginTop?: string,
    titleHeight?: string,
    imageHeight?: string,
    imageBackgroundPosition: string,
    imageWidth: string,
}

const sections: { [k: string]: Section } = {
    'tracce': {
        titleMarginTop: '75px',
        titleHeight: '30px',
        imageHeight: '30px',
        imageBackgroundPosition: '0px -30px',
        imageWidth: '150px',
    },
    'artificio_e_natura': {
        titleMarginTop: '75px',
        titleHeight: '30px',
        imageHeight: '30px',
        imageBackgroundPosition: '-167px -30px',
        imageWidth: '420px',
    },
    'compenetrazioni': {
        titleMarginTop: '75px',
        titleHeight: '30px',
        imageHeight: '30px',
        imageBackgroundPosition: '-605px -30px',
        imageWidth: '395px',
    },
    'morbosita': {
        titleMarginTop: '75px',
        titleHeight: '30px',
        imageHeight: '30px',
        imageBackgroundPosition: '-1018px -30px',
        imageWidth: '235px',
    },
    'elevation': {
        titleMarginTop: '70px',
        titleHeight: '35px',
        imageHeight: '35px',
        imageBackgroundPosition: '-859px -85px',
        imageWidth: '218px',
    },
    // More
    'reportage': {
        imageBackgroundPosition: '-1271px -30px',
        imageWidth: '235px',
    },
    'commercial_fotography': {
        imageBackgroundPosition: '0px -60px',
        imageWidth: '547px',
    },
    'freehand': {
        imageBackgroundPosition: '-1095px -90px',
        imageWidth: '225px',
    },
    // Reportage
    'borneo': {
        imageBackgroundPosition: '-565px -60px',
        imageWidth: '160px',
    },
    'cina': {
        imageBackgroundPosition: '-1528px -30px',
        imageWidth: '105px',
    },
    'cuba': {
        imageBackgroundPosition: '-747px -60px',
        imageWidth: '115px',
    },
    'islanda': {
        imageBackgroundPosition: '-884px -60px',
        imageWidth: '170px',
    },
    'mosca': {
        imageBackgroundPosition: '-1073px -60px',
        imageWidth: '140px',
    },
    'quebec': {
        imageBackgroundPosition: '-1235px -60px',
        imageWidth: '165px',
    },
    'senegal': {
        imageBackgroundPosition: '-476px -90px',
        imageWidth: '175px',
    },
    'yemen': {
        imageBackgroundPosition: '-668px -90px',
        imageWidth: '145px',
    },
}

const TitleWidget: React.FC<WithTitle> = (props) => {
    const section: Section | undefined = sections[props.section || '']

    return <>
        {section
            ? <div id="title" style={{
                marginTop: section.titleMarginTop || '75px',
                height: section.titleHeight || '30px'
            }}><img id="image"
                src={blank} alt=" "
                style={{
                    height: section.imageHeight || '30px',
                    backgroundPosition: section.imageBackgroundPosition,
                    width: section.imageWidth
                }} /></div>
            : !!props.section
                ? <div id="title"><img id="image" src={blank} alt=" " /></div>
                : <div id="title" className={props.title}><img id="image" src={blank} alt=" " /></div>
        }
    </>
}

export default TitleWidget