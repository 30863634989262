import React from 'react'

export type WithTranslation = {
    addClassName: string
    leftElement: React.ReactNode
    midElement: React.ReactNode
    rightElement: React.ReactNode
}

const TranslationWidget: React.FC<WithTranslation> = (props) => {
    return <React.Fragment>
        <div className={props.addClassName + " half right"}>{props.leftElement}</div>
        <div className={props.addClassName + " center"}>{props.midElement}</div>
        <div className={props.addClassName + " half left"}>{props.rightElement}</div>
    </React.Fragment>
}

export default TranslationWidget