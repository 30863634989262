import React from 'react';

export type WithSplitPage = {
    leftPane: React.ReactNode,
    centerPane?: React.ReactNode,
    rightPane: React.ReactNode,
    subtitle?: string
}

const SplitPageWidget: React.FC<WithSplitPage> = (props) => {
    return <>
        <div id="profile" >
            {!!props.subtitle
                ? <div className="year">{props.subtitle}</div>
                : <div style={{ marginTop: '61px' }} />
            }
            <div className="half right text" style={{ float: 'left', marginLeft: '10px' }}>{props.leftPane}</div>
            {!!props.centerPane && <div className="subtitle center" style={{ height: '1000px' }}>{props.centerPane}</div>}
            <div className="half left text" style={{ float: 'right', marginRight: '10px' }}>{props.rightPane}</div>
            <div style={{ clear: 'both' }} />
        </div>
        <div id="push" />
    </>
}

export default SplitPageWidget