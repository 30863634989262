import React from 'react';
import { Menu, Title } from '../Elements';
import { Link } from "react-router-dom";

const HomeWidget: React.FC = () => {
    const [state, setState] = React.useState({
        title: ''
    })

    function setTitle(title: string) {
        setState({ title: title })
    }

    return <div>
        <div id="gallery" className="wall">
            <Link id="tracce"
                to="/gallery/tracce/"
                onMouseEnter={() => setTitle("tracce")}
                onMouseLeave={() => setTitle('')} />
            <Link id="artificio_e_natura"
                to="/gallery/artificio_e_natura/"
                onMouseEnter={() => setTitle("artificio_e_natura")}
                onMouseLeave={() => setTitle('')} />
            <Link id="compenetrazioni"
                to="/gallery/compenetrazioni/"
                onMouseEnter={() => setTitle("compenetrazioni")}
                onMouseLeave={() => setTitle('')} />
            <Link id="morbosita"
                to="/gallery/morbosita/"
                onMouseEnter={() => setTitle("morbosita")}
                onMouseLeave={() => setTitle('')} />
            <Link id="elevation"
                to="/gallery/elevation/"
                onMouseEnter={() => setTitle("elevation")}
                onMouseLeave={() => setTitle('')} />
        </div>
        <Title title='' section={state.title} />
        <Menu />
    </div>
}
export default HomeWidget