import React from 'react';
import { Menu, SplitPage, Title } from '../Elements';

const CreditsWidget: React.FC = () => {
    return <>
        <Title title="disclaimer" />
        <SplitPage
            leftPane={<>
                © Mario Bianchi.<br />
                Tutti i diritti riservati.<br />
                E' vietata la riproduzione anche parziale delle immagini presenti nel sito senza l'autorizzazione scritta dell'autore.
            </>}
            rightPane={<>
                © Mario Bianchi.<br />
                All right reserved.<br />
                No portion of this site may be reproduced in any form or by any means without prior written permission of the author.
            </>}
        />
        <Menu />
    </>
}
export default CreditsWidget
