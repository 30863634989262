import React from "react"
import { GalleryImageMap, WithGallery } from "../model"

type GalleryMap = { [k in string]: WithGallery }


//  
const galleries: GalleryMap = {
    'Tracce': {
        translations: {
            titleIt: 'Tracce', titleEn: 'Signs',
            subtitleIt: 'presenze passate',
            subtitleEn: 'past presences',
            yearStart: '1972',
            yearEnd: '1980',
            descriptionIt: <>Registro i segni lasciati dal<br />
                contatto fisico dell'uomo:<br />impronte, sentieri, superfici<br />
                consumate, contaminazioni che<br />
                evidenziano la sua presenza.<br />
                Le tracce che provano l'esistenza<br />
                dell'uomo mi conducono a verificare<br />
                anche la mia presenza.</>,
            descriptionEn: <> I became aware of the signs man leaves<br />
                behind from his physical contact:<br />
                footprints, paths, surfaces expended,<br />
                contaminations that denote his presence.<br />
                Signs proving the existence of man led me<br />
                to authenticate my own presence.</>,
        },
        images: [
            { image: "/images/gallery01/gallery01.jpg", captionIt: "Morena" },
            { image: "/images/gallery01/gallery02.jpg", captionIt: "Sentieri", captionEn: "Paths" },
            { image: "/images/gallery01/gallery03.jpg", captionIt: "Sentiero", captionEn: "Path" },
            { image: "/images/gallery01/gallery04.jpg", captionIt: "Scala", captionEn: "Stair" },
            { image: "/images/gallery01/gallery05.jpg", captionIt: "Figure", captionEn: "Figures" },
            { image: "/images/gallery01/gallery06.jpg", captionIt: "Sentiero 2", captionEn: "Path 2" },
            { image: "/images/gallery01/gallery07.jpg", captionIt: "Sentieri 2", captionEn: "Paths 2" },
            { image: "/images/gallery01/gallery08.jpg", captionIt: "Scala 2", captionEn: "Stair 2" },
            { image: "/images/gallery01/gallery09.jpg", captionIt: "Scala 3", captionEn: "Stair 3" },
        ]
    },

    'Artificio e Natura': {
        translations: {
            titleIt: 'Artificio e Natura',
            titleEn: 'Artifice and Nature',
            yearStart: '1981',
            yearEnd: '1992',
            descriptionIt: <>La natura mostra la sua bellezza anche<br /> nei grandi cataclismi.Piccoli fenomeni<br /> riprodotti in studio con l'acqua e la luce<br />sembrano restituire con vigore le origini<br />catastrofiche che questi elementi<br />primordiali conservano nella loro<br />memoria sin dall'inizio del tutto.</>,
            descriptionEn: <>Nature reveals its beauty even through<br /> great cataclysms.Small phenomenon<br />reproduced in the studio with water and<br /> light appear to energetically restore the < br /> catastrophic origins that these primordial < br /> events have retained in their memory < br /> from the dawn of time.</>,
        },
        images: [
            {
                image: "/images/gallery02/gallery01.jpg",
                captionIt: "Aria, Terra ed Acqua",
                captionEn: "Air, Earth and Water"
            },
            {
                image: "/images/gallery02/gallery02.jpg",
                captionIt: "Atmosfere",
                captionEn: "Atmospheres"
            },
            {
                image: "/images/gallery02/gallery03.jpg",
                captionIt: "Luce ed Acqua",
                captionEn: "Light and Water"
            },
            {
                image: "/images/gallery02/gallery04.jpg",
                captionIt: "Luce",
                captionEn: "Light"
            },
            {
                image: "/images/gallery02/gallery05.jpg",
                captionIt: "Pesce",
                captionEn: "Fish"
            },
            {
                image: "/images/gallery02/gallery06.jpg",
                captionIt: "Terra, Acqua e Fuoco",
                captionEn: "Earth, Water and Fire"
            },
            {
                image: "/images/gallery02/gallery07.jpg",
                captionIt: "Aria, Acqua e Fuoco",
                captionEn: "Air, Water and Fire"
            },
        ]
    },
    'Compenetrazioni': {
        translations: {
            titleIt: 'Compenetrazioni', titleEn: 'Interpenetrations',
            yearStart: '1992', yearEnd: '1998',
            descriptionIt: <>Dalle testimonianze delle vittime di<br />
                stupro si apprende che "chi ne esce, lo fa portando con sé l'esperienza della morte".<br />
                (Ida Magli)<br />
                Ataviche paure affiorano sui visi di chi si<br />
                sottopone all'esperimento, la fotocamera<br />
                registra rigide posture di corpi morti con<br />
                disarticolazioni di manieristica memoria<br />
                e lo sguardo, rassegnato, tradisce chi sa<br />
                che anche i carnefici sono nostri figli.</>,
            descriptionEn: <>From the experiences of rape victims, we<br />
                learn that "those who come through it,<br />
                bring the experience of death with them".<br />
                (Ida Magli)<br />
                Primeval fears crossed the faces of the subjects<br />
                of the experiment, the camera registering rigid<br />
                poses of dead bodies with Mannerism-like<br />
                disjointedness and their expressions, resigned,<br />
                belied those who knew that even executioners<br />
                are our children.</>
        },
        images: [
            { image: "/images/gallery03/gallery01.jpg", captionIt: "Stephanie" },
            { image: "/images/gallery03/gallery02.jpg", captionIt: "Tamara" },
            { image: "/images/gallery03/gallery03.jpg", captionIt: "Samantha" },
            { image: "/images/gallery03/gallery04.jpg", captionIt: "Maryluz" },
            { image: "/images/gallery03/gallery05.jpg", captionIt: "Roberta" },
            { image: "/images/gallery03/gallery06.jpg", captionIt: "Anonymous" },
            { image: "/images/gallery03/gallery07.jpg", captionIt: "Vittoriana" },
            { image: "/images/gallery03/gallery08.jpg", captionIt: "Alessia" },
            { image: "/images/gallery03/gallery09.jpg", captionIt: "Alessandra Suarez" },
            { image: "/images/gallery03/gallery10.jpg", captionIt: "Isabella" },
            { image: "/images/gallery03/gallery11.jpg", captionIt: "Cristina" },
        ]
    },

    'Morbosità': {
        translations: {
            titleIt: 'Morbosità', titleEn: 'Morbosità',
            yearStart: '1999', yearEnd: '2007',
            descriptionIt: <>Vedo l'uomo come un batterio che infetta<br />
                il corpo in cui si stabilisce fino a<br />
                comprometterne la vita. Segni, masse,<br />
                descrivono l'infezione che avanza:<br />
                l'uomo, predestinato a lasciare tracce sul<br />
                suo cammino, é contagiato dai suoi stessi
                <br />segni ...<br />
                ed é Morbosità.</>,
            descriptionEn: <>I saw man as a bacteria that infects the<br />
                body it inhabits, in the end taking its life.<br />
                Signs and masses describe the advancing<br />
                infection: man, fated to leave traces of<br />
                his passage, is infected by his own
                <br />signs...<br />
                this was Morbosità.</>,
        },
        images: [
            { image: "/images/gallery04/gallery01.jpg", captionIt: "No comment N°1" },
            { image: "/images/gallery04/gallery02.jpg", captionIt: "No comment N°2" },
            { image: "/images/gallery04/gallery03.jpg", captionIt: "No comment N°3" },
            { image: "/images/gallery04/gallery04.jpg", captionIt: "No comment N°4" },
            { image: "/images/gallery04/gallery05.jpg", captionIt: "No comment N°5" },
            { image: "/images/gallery04/gallery06.jpg", captionIt: "No comment N°6" },
            { image: "/images/gallery04/gallery07.jpg", captionIt: "No comment N°7" },
            { image: "/images/gallery04/gallery08.jpg", captionIt: "No comment N°8" },
            { image: "/images/gallery04/gallery09.jpg", captionIt: "No comment N°9" },
            { image: "/images/gallery04/gallery10.jpg", captionIt: "No comment N°10" },
            { image: "/images/gallery04/gallery11.jpg", captionIt: "No comment N°11" },
            { image: "/images/gallery04/gallery12.jpg", captionIt: "No comment N°12" },
            { image: "/images/gallery04/gallery13.jpg", captionIt: "No comment N°13" },
        ]
    },

    'Élévation': {
        translations: {
            titleIt: 'Élévation', titleEn: 'Élévation',
            yearStart: '2008', yearEnd: '. . .',
            descriptionIt: <>"Fuggi lontano da questi morbosi miasmi,<br />
                vola a purificarti nell'aere superiore,<br />
                e bevi, come un puro e celestiale liquore,<br />
                il chiaro fuoco che colma i limpidi spazi."<br />
                (Charles Baudelaire)</>,
            descriptionEn: <>"Envole-toi bien loin de ces miasmes morbides;<br />
                Va te purifier dans l'air supérieur,<br />
                Et bois, comme une pure et divine liqueur,<br />
                Le feu clair qui remplit les espaces limpides."<br />
                (Charles Baudelaire)</>
        },
        images: [
            { image: "/images/gallery05/gallery01.jpg", captionIt: "Élévation  n.1" },
            //{ image: "/images/gallery05/gallery02.jpg", captionIt: "Élévation  n.2" },
            { image: "/images/gallery05/gallery03.jpg", video: "jI8Ixj7vIKU?si=gLUR0DTKVWCx33Sd", captionIt: "Élévation n.4", width: 560, height: 315 },
            { image: "/images/gallery05/gallery04.jpg", video: "d57a77yuimo?si=ugDU2xdMiXci_SJC", captionIt: "Élévation n.5", width: 560, height: 315 },
            { image: "/images/gallery05/gallery05.jpg", video: "RGOvhEuH9UY?si=qpsXntbolRc_Tgbu", captionIt: "Élévation n.6", width: 560, height: 315 },
        ]
    },

    'Freehand': {
        translations: {
            titleIt: 'Freehand', titleEn: 'Freehand',
            yearStart: '', yearEnd: '',
            descriptionIt: <></>,
            descriptionEn: <></>
        },
        images: [
            { image: "/images/gallery16/gallery01.jpg", captionIt: "Freehand 1" },
            { image: "/images/gallery16/gallery02.jpg", captionIt: "Freehand 2" },
            { image: "/images/gallery16/gallery03.jpg", captionIt: "Freehand 3" },
            { image: "/images/gallery16/gallery04.jpg", captionIt: "Freehand 4" },
            { image: "/images/gallery16/gallery05.jpg", captionIt: "Freehand 5" },
            { image: "/images/gallery16/gallery06.jpg", captionIt: "Freehand 6" },
        ]
    },

    'Commercial': {
        translations: {
            titleIt: 'Commercial', titleEn: 'Commercial',
            yearStart: '', yearEnd: '',
            descriptionIt: <></>,
            descriptionEn: <></>
        },
        images: [
            { image: "/images/gallery18/gallery01.jpg", captionIt: "Commercial 1" },
            { image: "/images/gallery18/gallery02.jpg", captionIt: "Commercial 2" },
            { image: "/images/gallery18/gallery03.jpg", captionIt: "Commercial 3" },
            { image: "/images/gallery18/gallery04.jpg", captionIt: "Commercial 4" },
            { image: "/images/gallery18/gallery05.jpg", captionIt: "Commercial 5" },
            { image: "/images/gallery18/gallery06.jpg", captionIt: "Commercial 6" },
            { image: "/images/gallery18/gallery07.jpg", captionIt: "Commercial 7" },
            { image: "/images/gallery18/gallery08.jpg", captionIt: "Commercial 8" },
            { image: "/images/gallery18/gallery09.jpg", captionIt: "Commercial 9" },
            { image: "/images/gallery18/gallery10.jpg", captionIt: "Commercial 10" },
            { image: "/images/gallery18/gallery11.jpg", captionIt: "Commercial 11" },
            { image: "/images/gallery18/gallery12.jpg", captionIt: "Commercial 12" },
            { image: "/images/gallery18/gallery13.jpg", captionIt: "Commercial 13" },
            { image: "/images/gallery18/gallery14.jpg", captionIt: "Commercial 14" },
            { image: "/images/gallery18/gallery15.jpg", captionIt: "Commercial 15" },
            { image: "/images/gallery18/gallery16.jpg", captionIt: "Commercial 16" },
            { image: "/images/gallery18/gallery17.jpg", captionIt: "Commercial 17" },
            { image: "/images/gallery18/gallery18.jpg", captionIt: "Commercial 18" },
            { image: "/images/gallery18/gallery19.jpg", captionIt: "Commercial 19" },
            { image: "/images/gallery18/gallery20.jpg", captionIt: "Commercial 20" },
            { image: "/images/gallery18/gallery21.jpg", captionIt: "Commercial 21" },
            { image: "/images/gallery18/gallery22.jpg", captionIt: "Commercial 22" },
            { image: "/images/gallery18/gallery23.jpg", captionIt: "Commercial 23" },
            { image: "/images/gallery18/gallery24.jpg", captionIt: "Commercial 24" },
            { image: "/images/gallery18/gallery25.jpg", captionIt: "Commercial 25" },
            { image: "/images/gallery18/gallery26.jpg", captionIt: "Commercial 26" },
            { image: "/images/gallery18/gallery27.jpg", captionIt: "Commercial 27" },
            { image: "/images/gallery18/gallery28.jpg", captionIt: "Commercial 28" },
            { image: "/images/gallery18/gallery29.jpg", captionIt: "Commercial 29" },
            { image: "/images/gallery18/gallery30.jpg", captionIt: "Commercial 30" },
            { image: "/images/gallery18/gallery31.jpg", captionIt: "Commercial 31" },
            { image: "/images/gallery18/gallery32.jpg", captionIt: "Commercial 32" },
        ]
    },

    'Borneo': {
        translations: {
            titleIt: 'Borneo', titleEn: 'Borneo',
            yearStart: '', yearEnd: '',
            descriptionIt: <></>,
            descriptionEn: <></>
        },
        images: [
            { image: "/images/gallery09/gallery01.jpg", captionIt: "Borneo 1" },
            { image: "/images/gallery09/gallery02.jpg", captionIt: "Borneo 2" },
            { image: "/images/gallery09/gallery03.jpg", captionIt: "Borneo 3" },
            { image: "/images/gallery09/gallery04.jpg", captionIt: "Borneo 4" },
        ]
    },

    'Cina': {
        translations: {
            titleIt: 'Cina', titleEn: 'Cina',
            yearStart: '', yearEnd: '',
            descriptionIt: <></>,
            descriptionEn: <></>
        },
        images: [
            { image: "/images/gallery10/gallery01.jpg", captionIt: "Cina 1" },
            { image: "/images/gallery10/gallery02.jpg", captionIt: "Cina 2" },
            { image: "/images/gallery10/gallery03.jpg", captionIt: "Cina 3" },
            { image: "/images/gallery10/gallery04.jpg", captionIt: "Cina 4" },
            { image: "/images/gallery10/gallery05.jpg", captionIt: "Cina 5" },
            { image: "/images/gallery10/gallery06.jpg", captionIt: "Cina 6" },
            { image: "/images/gallery10/gallery07.jpg", captionIt: "Cina 7" },
            { image: "/images/gallery10/gallery08.jpg", captionIt: "Cina 8" },
            { image: "/images/gallery10/gallery09.jpg", captionIt: "Cina 9" },
            { image: "/images/gallery10/gallery10.jpg", captionIt: "Cina 10" },
            { image: "/images/gallery10/gallery11.jpg", captionIt: "Cina 11" },
            { image: "/images/gallery10/gallery12.jpg", captionIt: "Cina 12" },
            { image: "/images/gallery10/gallery13.jpg", captionIt: "Cina 13" },
            { image: "/images/gallery10/gallery14.jpg", captionIt: "Cina 14" },
        ]
    },

    'Cuba': {
        translations: {
            titleIt: 'Cuba', titleEn: 'Cuba',
            yearStart: '', yearEnd: '',
            descriptionIt: <></>,
            descriptionEn: <></>
        },
        images: [
            { image: "/images/gallery17/gallery01.jpg", captionIt: "Cuba 1" },
            { image: "/images/gallery17/gallery02.jpg", captionIt: "Cuba 2" },
            { image: "/images/gallery17/gallery03.jpg", captionIt: "Cuba 3" },
            { image: "/images/gallery17/gallery04.jpg", captionIt: "Cuba 4" },
            { image: "/images/gallery17/gallery05.jpg", captionIt: "Cuba 5" },
            { image: "/images/gallery17/gallery06.jpg", captionIt: "Cuba 6" },
            { image: "/images/gallery17/gallery07.jpg", captionIt: "Cuba 7" },
            { image: "/images/gallery17/gallery08.jpg", captionIt: "Cuba 8" },
            { image: "/images/gallery17/gallery09.jpg", captionIt: "Cuba 9" },
            { image: "/images/gallery17/gallery10.jpg", captionIt: "Cuba 10" },
            { image: "/images/gallery17/gallery11.jpg", captionIt: "Cuba 11" },
        ]
    },

    'Islanda': {
        translations: {
            titleIt: 'Islanda', titleEn: 'Islanda',
            yearStart: '', yearEnd: '',
            descriptionIt: <></>,
            descriptionEn: <></>
        },
        images: [
            { image: "/images/gallery11/gallery01.jpg", captionIt: "Islanda 1" },
            { image: "/images/gallery11/gallery02.jpg", captionIt: "Islanda 2" },
            { image: "/images/gallery11/gallery03.jpg", captionIt: "Islanda 3" },
            { image: "/images/gallery11/gallery04.jpg", captionIt: "Islanda 4" },
            { image: "/images/gallery11/gallery05.jpg", captionIt: "Islanda 5" },
            { image: "/images/gallery11/gallery06.jpg", captionIt: "Islanda 6" },
            { image: "/images/gallery11/gallery07.jpg", captionIt: "Islanda 7" },
        ]
    },

    'Mosca': {
        translations: {
            titleIt: 'Mosca', titleEn: 'Mosca',
            yearStart: '', yearEnd: '',
            descriptionIt: <></>,
            descriptionEn: <></>
        },
        images: [
            { image: "/images/gallery12/gallery01.jpg", captionIt: "Mosca 1" },
            { image: "/images/gallery12/gallery02.jpg", captionIt: "Mosca 2" },
            { image: "/images/gallery12/gallery03.jpg", captionIt: "Mosca 3" },
            { image: "/images/gallery12/gallery04.jpg", captionIt: "Mosca 4" },
            { image: "/images/gallery12/gallery05.jpg", captionIt: "Mosca 5" },
            { image: "/images/gallery12/gallery06.jpg", captionIt: "Mosca 6" },
        ]
    },

    'Quebec': {
        translations: {
            titleIt: 'Quebec', titleEn: 'Quebec',
            yearStart: '', yearEnd: '',
            descriptionIt: <></>,
            descriptionEn: <></>
        },
        images: [
            { image: "/images/gallery13/gallery01.jpg", captionIt: "Quebec 1" },
            { image: "/images/gallery13/gallery02.jpg", captionIt: "Quebec 2" },
            { image: "/images/gallery13/gallery03.jpg", captionIt: "Quebec 3" },
            { image: "/images/gallery13/gallery04.jpg", captionIt: "Quebec 4" },
            { image: "/images/gallery13/gallery05.jpg", captionIt: "Quebec 5" },
        ]
    },

    'Senegal': {
        translations: {
            titleIt: 'Senegal', titleEn: 'Senegal',
            yearStart: '', yearEnd: '',
            descriptionIt: <></>,
            descriptionEn: <></>
        },
        images: [
            { image: "/images/gallery14/gallery01.jpg", captionIt: "Senegal 1" },
            { image: "/images/gallery14/gallery02.jpg", captionIt: "Senegal 2" },
            { image: "/images/gallery14/gallery03.jpg", captionIt: "Senegal 3" },
            { image: "/images/gallery14/gallery04.jpg", captionIt: "Senegal 4" },
            { image: "/images/gallery14/gallery05.jpg", captionIt: "Senegal 5" },
        ]
    },

    'Yemen': {
        translations: {
            titleIt: 'Yemen', titleEn: 'Yemen',
            yearStart: '', yearEnd: '',
            descriptionIt: <></>,
            descriptionEn: <></>
        },
        images: [
            { image: "/images/gallery15/gallery01.jpg", captionIt: "Yemen 1" },
            { image: "/images/gallery15/gallery02.jpg", captionIt: "Yemen 2" },
            { image: "/images/gallery15/gallery03.jpg", captionIt: "Yemen 3" },
            { image: "/images/gallery15/gallery04.jpg", captionIt: "Yemen 4" },
            { image: "/images/gallery15/gallery05.jpg", captionIt: "Yemen 5" },
            { image: "/images/gallery15/gallery06.jpg", captionIt: "Yemen 6" },
            { image: "/images/gallery15/gallery07.jpg", captionIt: "Yemen 7" },
            { image: "/images/gallery15/gallery08.jpg", captionIt: "Yemen 8" },
        ]
    },



}

class GalleryStore {

    getGallery(id: string): WithGallery {
        const out = galleries[id]
        return out || {
            translations: {
                titleIt: 'No Gallery found',
                titleEn: 'No Gallery found',
                yearStart: '',
                yearEnd: '',
                descriptionIt: '',
                descriptionEn: '',
            },
            images: []
        }
    }

    getGalleriesImages(names: string[]): GalleryImageMap {
        let out = {} as GalleryImageMap
        names.map(name => this.getGallery(name))
            .filter(g => g.images.length > 0)
            .forEach(element => {
                out[element.translations.titleIt] = element.images
            })
        return out
    }
}

const galleryInstance = new GalleryStore()

export default galleryInstance