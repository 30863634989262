import React from 'react'
import './App.css'
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import {
  Contact,
  Credits,
  Disclaimer,
  Exhibitions,
  Gallery,
  Home,
  More,
  Profile,
  Reportage,
  Review,
  Videos,
} from './components/Pages'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"
import GalleryStore from './store/GalleryStore'
import { WithGallery } from './model'

function gg(id: string): WithGallery {
  return GalleryStore.getGallery(id)
}

function App() {
  return (
    <BrowserRouter>
      <div id="content">
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/gallery/" element={<Home />} />

            <Route path="/gallery/tracce/" element={<Gallery {...gg('Tracce')} />} />
            <Route path="/gallery/artificio_e_natura/" element={<Gallery {...gg('Artificio e Natura')} />} />
            <Route path="/gallery/compenetrazioni/" element={<Gallery {...gg('Compenetrazioni')} />} />
            <Route path="/gallery/morbosita/" element={<Gallery {...gg('Morbosità')} />} />
            <Route path="/gallery/elevation/" element={<Gallery {...gg('Élévation')} />} />

            <Route path="/contact/" element={<Contact />} />
            <Route path="/credits/" element={<Credits />} />
            <Route path="/disclaimer/" element={<Disclaimer />} />
            <Route path="/exhibitions/" element={<Exhibitions />} />
            <Route path="/more/" element={<More />} />
            <Route path="/profile/" element={<Profile />} />
            <Route path="/more/reportage/" element={<Reportage />} />
            <Route path="/review/" element={<Review />} />
            <Route path="/videos/" element={<Videos />} />
          </Routes>
        </div>
      </div>
      <div id="footer">
        <div className="right">© <Link className="hidden" to="/more/">Mario Bianchi</Link>. Tutti i diritti riservati.</div>
        <div className="left">© Mario Bianchi. All right reserved.</div>
      </div>
    </BrowserRouter>
  );
}

export default App;
