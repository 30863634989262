import React from 'react';
import { Menu, SplitPage, Title } from '../Elements';
import Lightbox from "yet-another-react-lightbox";

const ProfileWidget: React.FC = () => {
    const [state, setState] = React.useState({
        open: false,
        index: -1,
    })

    function openGallery(num: number) {
        setState({ open: true, index: num - 1 })
    }

    function close() {
        setState({ open: false, index: -1 })
    }

    return <div>
        <Title title="profile" />
        <SplitPage subtitle='Mario Bianchi'
            leftPane={<>
                Nasce il 15 maggio 1949 a Cagno (Como).<br /><br />A sei anni segue il padre che si<br />trasferisce con la famiglia a Milano.<br /><br />Nella Metropoli acquisisce il diploma<br />di maturità artistica presso il<br />Liceo Artistico "Beato Angelico".<br /><br />
                Negli anni sessanta  partecipa a varie<br />mostre estemporanee e vince il primo<br />premio "Medaglia d'oro Città di<br />Melegnano" che dedica a Giuseppe<br />Motti "Pittore della gente del Po",<br />suo primo maestro d'arte.<br /><br />
                Nel 1969 si iscrive all'Accademia di<br />Belle Arti di Brera dove frequenta con<br />lo stesso interesse i corsi di Alik<br />Cavaliere, le riunioni del collettivo<br />studentesco ed il corso di storia dell'arte<br />di Raffaele De Grada.<br /><br />
                Nel 1970 si sposa e nel 1971<br />nasce il primo figlio,<br />Raoul Simone, nel 1972 nasce<br />Francesca Giovanna.<br /><br />
                Nell'estate del '72, prima dell'inizio del<br />terzo anno di Accademia di Belle Arti,<br />presenta la Sua cartella artistica al Liceo<br />Artistico di Varese e viene assunto in<br />qualità di Docente di Discipline Pittoriche.<br /><br />Del medesimo anno è la prima ricerca<br />fotografica, "TRACCE": in Turchia,<br />Siria e Giordania registra le immagini<br />più significative.<br /><br />
                Dal 1980 si dedica alla fotografia<br />pubblicitaria mentre,<br />contemporaneamente, da luce al<br />decennio "ARTIFICIO E NATURA".<br /><br />
                Diversi Comuni dedicano spazi a questa<br />mostra itinerante; il più apprezzato è<br />offerto dal  Comune di Como, che<br />espone le opere nel Chiostro di<br />S. Eufemia: Gualtiero Gualtieri<br />ne commenta  il contenuto con<br />un appassionato scritto.<br /><br />Nel 1994 sottopone alla scrittrice<br />Luce Irigaray,  psicanalista-femminista,<br />le prime immagini della nuova ricerca<br />"COMPENETRAZIONI"<br />
                Nasce un acceso scambio di opinioni<br /> che caratterizzerà tutta la nuova produzione.<br /><br />
                Il filosofo Valerio Crugnola, sull'autore<br /> e sui contenuti della ricerca, ha condotto<br /> una attenta e profonda analisi sull'intero caso.<br /><br />Il Beato Maestro del Provvisorio,<br />Sergio Fiucci, ha dedicato poesie e saggi<br />che hanno confortato e guidato l'amico<br />fotografo durante tutta l'evoluzione<br />della contrastata  avventura.<br /><br />
                Nel 1999 inizia "MORBOSITÀ", e nel 2008,<br /> in un costruttivo connubio artistico, <br />la pittrice Cristina Maddalena<br /> accende nel fotografo il desiderio<br /> di registrare gli umani tentativi<br /> di elevarsi attraverso l'arte<br /> ed è "ÉLÉVATION".</>}
            centerPane={<>
                <div className="box" style={{ marginTop: '100px' }} onClick={() => openGallery(1)} />
                <div className="box" style={{ marginTop: '200px' }} onClick={() => openGallery(2)} />
                <div className="box" style={{ marginTop: '200px' }} onClick={() => openGallery(3)} />
                <div className="box" style={{ marginTop: '200px' }} onClick={() => openGallery(4)} />
                <div className="box" style={{ marginTop: '200px' }} onClick={() => openGallery(5)} /></>}
            rightPane={<>
                Was born on 15 May 1949 in Cagno (Como).<br /><br />At age six, he went with his father who<br />transferred the family to Milan.<br /><br />He passed the arts diploma in the city,<br />at the "Beato Angelico" vocational<br />art school.<br /><br />
                In the 1960s, he took part in a number of<br />extemporary exhibitions and won the<br />"Medaglia d'oro Citta' di Melegnano"<br />which he dedicated to Giuseppe Motti<br />"Pittore della gente del Po" (Painter of<br />River Po peoples), his first art master.<br /><br />In 1969, he enrolled in Brera Accademia<br />di Belle Arti [Fine Arts Academy] where<br />he attended with equal enthusiasm the<br />courses of Alik Cavaliere, students'<br />union meetings and Raffaele De Grada's<br />history of art course.<br /><br />
                In 1970 he married and had two<br />children: the first, Raoul Simone,<br />was born in 1971 and the<br />second, Francesca Giovanna, in 1972.<br /><br />
                In summer 1972, before commencing his<br />third year at the Accademia di Belle<br />Arti, he submitted his artistic book to<br />Varese vocational art school and was<br />taken on to teach Pictorial Studies.<br /><br />His first photographic research,<br />"TRACCE" [signs], came to life that<br />same year, his most poignant images<br />captured in Turkey, Syria and Jordan.<br /><br />
                Since 1980, he has been doing commercial<br />photography whilst bringing to light the<br />decade "ARTIFICIO E NATURA"<br />[Artifice and Nature] at the same time.<br /><br />
                Many municipalities have hosted this<br />touring exhibition; the one in Como<br />where his work is exhibited in the<br />Cloister of St. Eufemia, has received<br />the most attention: Gualtiero Gualtieri<br />wrote an impassioned critique<br />on its contents.<br /><br />
                In 1994, he showed Luce Irigaray, writer,<br />psychoanalyst and feminist, the first pictures<br />in his new series entitled"COMPENETRAZIONI"<br />[Interpenetrations].
                An animated exchange of<br />opinions gave rise to the profound enquiry<br />that characterized the entire new series.<br /><br />
                The philosopher Valerio Crugnola conducted a<br />careful and deep analysis of the whole case,<br />spanning both the author and its contents alike.<br /><br />The Beato Maestro del Provvisorio,<br />Sergio Fiucci, wrote poems and essays<br />on the subject that comforted and guided<br />his friend the photographer during the<br />entire evolution of the contested adventure.<br /><br />
                In 1999 "MORBOSITÀ" began. In 2008,<br />the artist Cristina Maddalena<br /> aroused the interest of the photographer<br />	 Mario Bianchi, to explore the need<br /> for the humanity to improve their<br /> outlook through the arts, so<br /> was born "ÉLÉVATION".</>}
        />
        <Menu />
        <Lightbox
            index={state.index}
            open={state.open}
            close={close}
            controller={{closeOnBackdropClick: true}}
            render={{
                buttonPrev: () => null,
                buttonNext: () => null,
              }}
            slides={[
                {
                    type: "image",
                    src: "/images/profile/profile_01.jpg",
                    alt: "Profile 01",
                },
                {
                    type: "image",
                    src: "/images/profile/profile_02.jpg",
                    alt: "Profile 02",
                },
                {
                    type: "image",
                    src: "/images/profile/profile_03.jpg",
                    alt: "Profile 03",
                },
                {
                    type: "image",
                    src: "/images/profile/profile_04.jpg",
                    alt: "Profile 04",
                },
                {
                    type: "image",
                    src: "/images/profile/profile_05.jpg",
                    alt: "Profile 05",
                },
            ]}
        />

    </div>
}
export default ProfileWidget