import React from 'react';
import { Menu, Title } from '../Elements';
import { Link } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import GalleryStore from '../../store/GalleryStore'

const MoreWidget: React.FC = () => {
    const images = GalleryStore.getGalleriesImages(['Commercial', 'Freehand'])
    const [state, setState] = React.useState({
        open: false,
        selected: '',
        title: '',
    })

    function openCommercial() {
        setState({ ...state, open: true, selected: 'Commercial' })
    }

    function openFreehand() {
        setState({ ...state, open: true, selected: 'Freehand' })
    }

    function close() {
        setState({ ...state, open: false, selected: '' })
    }

    function setTitle(title: string) {
        setState({ ...state, title: title })
    }

    return <>
        <div id="more" className="wall">
            <Link id="reportage"
                to="/more/reportage/" 
                onMouseEnter={() => setTitle("reportage")}
                onMouseLeave={() => setTitle('')}/>
            <div className="image"
                id="commercial_fotography"
                onClick={openCommercial} 
                onMouseEnter={() => setTitle("commercial_fotography")}
                onMouseLeave={() => setTitle('')}/>
            <div className="image"
                id="freehand"
                onClick={openFreehand} 
                onMouseEnter={() => setTitle("freehand")}
                onMouseLeave={() => setTitle('')}/>
        </div>
        <Title title='' section={state.title} />
        {state.selected.length > 0 && <Lightbox
            plugins={[Captions]}
            open={state.open}
            close={close}
            controller={{closeOnBackdropClick: true}}
            slides={images[state.selected].map((e, i) => {
                return {
                    type: "image",
                    src: e.image,
                    title: e.captionIt,
                    description: e.captionEn,
                    alt: 'Image ' + (i + 1),
                }
            })}
        />}
        <Menu />
    </>
}
export default MoreWidget