import React from 'react';
import { Menu, Title } from '../Elements';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import GalleryStore from '../../store/GalleryStore'

const MoreWidget: React.FC = () => {
    const images = GalleryStore.getGalleriesImages(['Borneo', 'Cina', 'Cuba', 'Islanda', 'Mosca', 'Quebec', 'Senegal', 'Yemen',])
    const [state, setState] = React.useState({
        open: false,
        selected: '',
        title: '',
    })

    function openGallery(name: string) {
        setState({ ...state, open: true, selected: name })
    }

    function close() {
        setState({ ...state, open: false, selected: '' })
    }

    function setTitle(title: string) {
        setState({ ...state, title: title })
    }

    return <>
        <div id="reportage" className="wall">
            <div className="image"
                id="borneo"
                onClick={() => openGallery("Borneo")}
                onMouseEnter={() => setTitle("borneo")}
                onMouseLeave={() => setTitle('')} />
            <div className="image"
                id="cina"
                onClick={() => openGallery("Cina")}
                onMouseEnter={() => setTitle("cina")}
                onMouseLeave={() => setTitle('')} />
            <div className="image"
                id="cuba"
                onClick={() => openGallery("Cuba")}
                onMouseEnter={() => setTitle("cuba")}
                onMouseLeave={() => setTitle('')} />
            <div className="image"
                id="islanda"
                onClick={() => openGallery("Islanda")} /><br
                onMouseEnter={() => setTitle("islanda")}
                onMouseLeave={() => setTitle('')} />
            <div className="image"
                id="mosca"
                onClick={() => openGallery("Mosca")}
                onMouseEnter={() => setTitle("mosca")}
                onMouseLeave={() => setTitle('')} />
            <div className="image"
                id="quebec"
                onClick={() => openGallery("Quebec")}
                onMouseEnter={() => setTitle("quebec")}
                onMouseLeave={() => setTitle('')} />
            <div className="image"
                id="senegal"
                onClick={() => openGallery("Senegal")}
                onMouseEnter={() => setTitle("senegal")}
                onMouseLeave={() => setTitle('')} />
            <div className="image"
                id="yemen"
                onClick={() => openGallery("Yemen")}
                onMouseEnter={() => setTitle("yemen")}
                onMouseLeave={() => setTitle('')} />
        </div>
        <Title title='' section={state.title} />
        {state.selected.length > 0 && <Lightbox
            plugins={[Captions]}
            open={state.open}
            close={close}
            controller={{closeOnBackdropClick: true}}
            slides={images[state.selected].map((e, i) => {
                return {
                    type: "image",
                    src: e.image,
                    title: e.captionIt,
                    description: e.captionEn,
                    alt: 'Image ' + (i + 1),
                }
            })}
        />}
        <Menu />
    </>
}
export default MoreWidget